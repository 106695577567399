.reacket-connectors {
  display: flex;
}
.reacket-connector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 4rem;
}

.reacket-horizontal-line {
  border: solid 1px #ccc;
}
