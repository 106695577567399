.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* orthoplex */
/* loading data table */
.p-datatable .p-datatable-loading-icon.p-icon {
  color: #616ed3;
}

.p-datatable-loading-overlay {
  background-color: #ffffff90 !important;
}

/* column filter */
.p-column-filter-remove-button {
  display: none;
}

.p-column-filter-constraint:nth-child(1) {
  padding-bottom: 0px !important;
  border: 0px !important;
}
.p-column-filter-constraint:nth-child(2) {
  padding-top: 8px !important;
  border: 0px !important;
}

/* disabled input */

.disabledInput,
.disabledInput input {
  background-color: #e4e4e4;
  color: rgb(165, 165, 165);
  pointer-events: none;
}

/* hide clear button from calender */
.calender-clear-btn {
  display: none;
}

/* speedballHub */
input {
  border-width: 0 !important;
}

.p-dropdown {
  border: none !important;
}

a {
  text-decoration: none;
}

/* uploadInput */
input[type='file']::file-selector-button {
  /* margin: 10px 10px 10px 0px; */
  margin-right: 10px;
  border: none;
  background: #00000000;
  padding: 0px;
  color: #5472d4;
  font-weight: 700;
  font-size: medium;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

input[type='file']::file-selector-button:hover {
  color: #bfc9ed;
}

/* DataTable */

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr,
.p-datatable .p-datatable-header {
  background-color: #f5f5f5 !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
  /* background: #5472d4 !important; */
}

.p-datatable-loading-overlay {
  background-color: #00000005 !important;
}

.p-datatable.p-datatable-selectable
  .p-datatable-tbody
  > tr.p-selectable-row:focus {
  outline: none !important;
}

/* stepper */
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: #ffffff00 !important;
}

/* TabMenu */

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

/* Image slider */
/* ... Existing CSS ... */

.slider-container {
  position: relative;
  overflow: hidden;
}

.slider {
  display: flex;
  width: 1500%; /* Total width should be the number of slides times 100% */
  animation: slide 45s linear infinite;
}

.slide {
  flex: 1;
  height: 100vh;
  background-size: cover;
  background-position: center;
  animation: zoom 45s alternate infinite;
  transform-origin: center;
  transition: background 800ms ease; /* Smooth transition over 0.5 seconds */
}

.fixed-text {
  position: absolute;
  left: 33.33%;
}

@media only screen and (max-width: 991px) {
  .fixed-text {
    left: 50%;
  }
}

/* Add the background images for each slide */

/* Add background images for the other slides ... */

@keyframes slide {
  0%,
  100% {
    transform: translateX(0);
  }
  /* Distribute evenly based on the number of slides */
  6.67%,
  106.67%,
  206.67%,
  306.67%,
  406.67%,
  506.67%,
  606.67%,
  706.67%,
  806.67%,
  906.67%,
  1006.67%,
  1106.67%,
  1206.67%,
  1306.67%,
  1406.67% {
    transform: translateX(-100%);
  }
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  33.3% {
    transform: scale(1.1);
  }
  66.6% {
    transform: scale(1.05);
  }
}
