.reacket-round-header {
    width: 20rem;
    margin-right: 8rem;
    padding: 0.2rem 0;
    font-size: 120%;
    font-weight:bold;
    margin-left: 2rem;
    &.reacket-last-round {
      margin-right: 0;
    }
}