$backgroundColor: #f6f6f6;
$lightTextColor: #999;
$darkTextColor: #333;
$reverseTextColor: white;
$loserBorder: solid 0.2rem $lightTextColor;
$winnerTextColor: rgb(15, 172, 15);
$reverseWinnerTextColor: rgb(142, 233, 142);
$winnerBorder: solid 0.2rem $winnerTextColor;
$winnerBackgroundColor: #ebfaeb;
$highlightedBackgroundColor: #255d85;
$highlightedBorder: solid 0.2rem #1d8cd6;
$playerSeperatorBorder: solid 1px #ccc;

.reacket-player {
  padding: 0.5rem 0;
  background: $backgroundColor;
  border-left: $loserBorder;
  cursor: pointer;
  transition: all 0.4s ease-out;
  display: flex;
  justify-content: space-between;

  .reacket-player-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    flex: 1 1 auto;
    color: $lightTextColor;
  }
  .reacket-player-seed,
  .reacket-player-score {
    flex: 0 0 auto;
    padding: 0 1rem;
    color: $lightTextColor;
  }

  &.reacket-highlighted {
    background-color: $highlightedBackgroundColor;
    color: $reverseTextColor;
    border-left: $highlightedBorder;
    .reacket-player-seed,
    .reacket-player-score,
    .reacket-player-name {
      color: $reverseTextColor;
    }
  }
  &:first-child {
    border-bottom: $playerSeperatorBorder;
  }
}

.reacket-winner {
  background-color: $winnerBackgroundColor;
  border-left: $winnerBorder;
  .reacket-player-score {
    font-weight: bold;
    color: $winnerTextColor;
  }
  .reacket-player-name {
    color: $darkTextColor;
  }
  &.reacket-highlighted {
    .reacket-player-score {
      color: $reverseWinnerTextColor;
    }
    .reacket-player-name {
      color: $reverseTextColor;
    }
  }
}
