.reacket-match {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  .reacket-match-id {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 2rem;
    font-weight:bold;
    color: #999;
  }
  .reacket-players {
    width: 20rem;
  }
}